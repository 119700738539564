import React from 'react';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Flex, Box, Icon } from '@cowsquare/design';
import { FOOTER_SERVICE, FOOTER_MORE, FOOTER_OTHER } from '../../../constants/footer';
import Logo from './Logo';

const Wrapper = styled(Box)`
  background-color: ${themeGet('colors.primary')};
`;

const FooterIcon = styled(Icon).attrs({
  color: 'whites.3',
})`
  &:hover,
  &:active {
    color: ${themeGet('colors.whites.0')};
  }
`;

const FooterInfos = styled(Box).attrs({
  mb: ['48px', 0],
})`
  width: 240px;
  color: ${themeGet('colors.whites.0')};
  font-size: 12px;

  ${FooterIcon} {
    margin-right: ${themeGet('space.4')}px;
    cursor: pointer;
  }

  ${FooterIcon} &:last-child {
    margin-right: 0;
  }
`;

const FooterInfo = styled(Flex).attrs({
  justifyContent: ['center', 'flex-start'],
})``;

const FooterList = styled(Box).attrs({
  as: 'ul',
  mb: ['48px', 0],
})`
  width: 152px;
  color: ${themeGet('colors.whites.5')};
  font-size: 12px;
  list-style: none;
  padding-left: 0;
  margin-top: 0;

  &:last-child {
    margin-bottom: 0;
  }
`;

const FooterLink = styled(Link)`
  color: ${themeGet('colors.whites.5')};
  font-size: 12px;
  text-decoration: none;

  &:hover,
  &:active {
    color: ${themeGet('colors.whites.0')};
  }
`;

const FooterItem = styled(Flex).attrs({
  as: 'li',
  mr: [0, 6],
  height: '24px',
  justifyContent: ['center', 'flex-start'],
  alignItems: ['center', 'flex-start'],
})`
  &:last-child {
    margin-right: 0;
  }
`;

const FooterTitle = styled(FooterItem)`
  margin-bottom: ${themeGet('space.4')}px;
  color: ${themeGet('colors.whites.0')};
  font-size: ${themeGet('fontSizes.h5')};
`;

const Copyright = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  font-size: ${themeGet('fontSizes.text')};
  color: ${themeGet('colors.whites.0')};
  background-color: ${themeGet('colors.blacks.5')};
`;

const Footer = () => {
  return (
    <Wrapper>
      <Box py={7}>
        <Flex flexDirection={['column', 'row']} justifyContent="center" alignItems={['center', 'flex-start']}>
          <FooterInfos>
            <FooterInfo>
              <Box mb={50}>
                <Link to="/">
                  <Logo />
                </Link>
              </Box>
            </FooterInfo>
            <FooterInfo>
              <Box mb={4}>
                <a href="https://www.facebook.com/vontravel" target="_blank" rel="noopener noreferrer">
                  <FooterIcon name="FACEBOOK" />
                </a>
                <a href="mailto:enquiry@vontravels.com">
                  <FooterIcon name="EMAIL" />
                </a>
              </Box>
            </FooterInfo>
            <FooterInfo>旅遊牌照號碼 : 354418</FooterInfo>
          </FooterInfos>
          <FooterList>
            <FooterTitle>我們的服務</FooterTitle>
            {R.map(item => (
              <FooterItem key={item.name}>
                <FooterLink to={item.link}>{item.name}</FooterLink>
              </FooterItem>
            ))(FOOTER_SERVICE)}
          </FooterList>
          <FooterList>
            <FooterTitle>更多</FooterTitle>
            {R.map(item => (
              <FooterItem key={item.name}>
                <FooterLink to={item.link}>{item.name}</FooterLink>
              </FooterItem>
            ))(FOOTER_MORE)}
          </FooterList>
          <FooterList>
            <FooterTitle>其他</FooterTitle>
            {R.map(item => (
              <FooterItem key={item.name}>
                <FooterLink to={item.link}>{item.name}</FooterLink>
              </FooterItem>
            ))(FOOTER_OTHER)}
          </FooterList>
        </Flex>
      </Box>
      <Copyright>Copyright © 2019 - {new Date().getFullYear()} Von travel. All rights reserved.</Copyright>
    </Wrapper>
  );
};

export default Footer;
