import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { history } from '../history';
import { Layout, SimpleLayout, ErrorLayout } from './layouts/Layout';
import ScrollToTop from './components/ScrollToTop';
import {loadStripe} from '@stripe/stripe-js';
import {
  Elements
} from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_test_51JLMdwEg4toAK1iyQpMjM18ly26pec8sjcYYBP8EMod8qM7qhFWJUWj9H7MCXLXpkO4rdTnd6W9utFNCluUIHI7J007iksw752');
export default () => (
  <Router history={history}>
    <QueryParamProvider ReactRouterRoute={Route}>
      <Elements stripe={stripePromise}>
      <>
        {/* prettier-ignore */}
        <Switch>
          <Route exact path="/自助旅遊計畫" component={SimpleLayout} />
          <Route path="/error" component={ErrorLayout}/>
          <Route path="/" component={Layout}></Route>
        </Switch>
        <ScrollToTop />
      </>
      </Elements>
    </QueryParamProvider>
  </Router>
);
