import { Box, Checkbox, Form, Input, Notification, Radio, Select, Text } from '@cowsquare/design';
import { useStripe } from '@stripe/react-stripe-js';
import { themeGet } from '@styled-system/theme-get';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Field, Form as FinalForm } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as rules from '../../utils/validate';

const FormItem = Form.FormItem;

const Title = styled(Box).attrs({ as: 'h1', m: 0, mb: 5 })`
  font-size: ${themeGet('fontSizes.h2')};
  line-height: 1.5;
  font-weight: 500;
  &.border{
    border-bottom: 1px solid #0080c8;
  }
`;
const SpanBlue = styled(Box).attrs({ as: 'span' })`
 color: #0080c8
`;

const LeftBox = styled(Box).attrs({
  width: '49%',
  mb: '64px',
  px: ['16px', '24px'],
  pt: ['16px', '24px'],
  pb: '24px',
})`
  border: 2px solid #0080c8;
  margin-right: 1%;
  @media (max-width:640px){
    width:100%;
    margin-bottom:30px;
  }
`;
const RightBox = styled(Box).attrs({
  width: '49%',
})`
 @media (max-width:640px){
    width:100%;
  }
  
`;
const MainBox = styled(Box).attrs({
  display: 'flex',
  maxWidth: '904px',
  margin:'30px auto',
  justifyContent:'center',
  
})`
  position:relative;
  margin-bottom: 100px;
  form{
    display:flex;
    @media (max-width:640px){
    flex-direction:column;
    }
  }
  @media (max-width:640px){
    flex-direction:column;
    padding:10px;
    margin-bottom:120px;
  }
`;
const FlexBox = styled(Box).attrs({
  display: 'flex',
  justifyContent:'space-between'
})``;
const BorderBox = styled(Box).attrs({
  padding: '15px'
})`
  border:2px solid #0080c8;
  margin-bottom:20px;
`;

const DefaulBox = styled(Box).attrs({
  padding: '15px'
})`
  .StripeElement {
    padding: 9px 5px;
    border: 2px solid #0080c8;
    border-radius: 8px;
  }
  &.payment-button{
    .card{
      border: 2px solid #0080c8;
      &::placeholder {
          color: #32325dcc;
          font-weight:600
      }
    }
  }
`;
const TextSmall = styled(Box).attrs({ as: 'p'})`
  font-size:15px;
  line-height:25px;
  &.error{
    color:red;
    font-size:14px;
    margin: 5px;
  }
`;

const SubmitButton = styled(Box).attrs({ as: 'button', m: 0, mt: 5 })`
  background: #0080c8;
  color: #fff;
  padding 10px;
  width: 100%;
  border:none;
  cursor: pointer;
`;
const TextBlue = styled(Box).attrs({ as: 'p', m: 0, mb: 5 })`
  color: #0080c8;
  &.text-right{
    text-align: right;
  }
  &.notSpace{
    margin-bottom:0;
  }
`;

const TITLE = [
  { value: '先生', name: '先生' },
  { value: '女士', name: '女士' },
  { value: '小姐', name: '小姐' },
];



const CheckoutForm = () => {
  const history = useHistory();
  // eslint-disable-next-line
  const [cookies, setCookie, removeCookie] = useCookies(['tourFinal']);
  const [loading, setLoading ] = useState(false)
  const [check, setCheck ] = useState(false)
  const [errorCheck, setErrorCheck ] = useState(false)
  const tour = cookies.tourFinal;
  console.log(tour)
  const stripe = useStripe();

  const finalSubmit =  async (data) => {
    if(!check){
      setErrorCheck(true)
      return false
    }
     const dataClient = {
          "first_name": data.fname,
          "last_name":  data.lname,
          "salutation": data.gender.value,
          "phone": data.phone,
          "email": data.email,
          "address": data.address,
          "tour_name": tour.name,
          "plan_dates": tour.planDate,
          "number_people": n_people,
          "number_package": n_package,
          "final_price": tour.price,
          "payment_date": new Date(),
          "other_address":data.other_address,
          "checkout_on": 'tour'
    }
    try {
        if(!loading){
            setLoading(true);
            // const response = await axios.post("https://3fee1f594be4.ngrok.io/create-checkout-session", dataClient)
            const response = await axios.post("https://ninja-it.asia/create-checkout-session", dataClient)

            if(response.data.session) {
              dataClient['payment_id'] = response.data.session.id;
              dataClient['order_id'] = response.data.session.payment_intent;
              dataClient['payment_method'] = 'card';
              setCookie('info', dataClient);
              setCookie('payId', response.data.session.id);
              window.location = response.data.sessionUrl
            }
          }
        }
    catch (err) {
            console.log("Error", err)
            Notification.error({
                  message: '已送出表單',
                  description: err,
            });
        }
  };
  const handleCheckbox = (e)=> {
    setCheck(e.target.checked);
    setErrorCheck(!e.target.checked)
  }
  let n_people = '';
  let n_package = '';
  const textPaser = () => {
  // eslint-disable-next-line
      tour.package.map((item) =>  {
        if(item.value !== 0 ){
          n_people += item.key+item.value+'位, ';
          n_package += item.key + ' : ' + (item.value * tour.itemPrice) + '    ';
        }
      })
  }

   useEffect(() => {   
    if(tour === 'undefined' || !tour){
      history.replace(`/`);
    }else{    
      textPaser();
    }
  });
  return (
    <MainBox>
       <FinalForm
        onSubmit={(formValues) => finalSubmit( formValues)}
      >
        {({ handleSubmit, submitting }) => (
          <Form onSubmit={handleSubmit}>
            <LeftBox>
            <Title>基本資料</Title>
            <Field name="gender" validate={rules.required('此欄位必填')}>
                          {({ input, meta }) => (
                            <Form.FormItem input={input} meta={meta} label="稱謂" required>
                              <Select {...input} placeholder="稱謂" options={TITLE} size="large" />
                            </Form.FormItem>
                          )}
              </Field>
            <FlexBox>
              
              <Field name="fname" validate={rules.required('請輸入姓名')}>
                {({ input, meta }) => (
                  <FormItem label="名字" input={input} meta={meta} required>
                    <Input {...input} size="large" placeholder="名字" />
                  </FormItem>
                )}
              </Field>
              <Field name="lname" validate={rules.required('請輸入姓名')}>
                {({ input, meta }) => (
                  <FormItem label="姓氏" input={input} meta={meta} required>
                    <Input {...input} size="large" placeholder="姓氏" />
                  </FormItem>
                )}
              </Field>
            </FlexBox>
            <TextBlue>(須與旅遊證件相同）</TextBlue>
             <Field name="phone" validate={rules.required('請輸入姓名')}>
              {({ input, meta }) => (
                <>
                <FormItem label="電話號碼: " input={input} meta={meta} required>
                  <Input {...input} size="large" placeholder="請輸入電話號碼" />
                </FormItem>
                <TextBlue>(可接收whatsapp或signal訊息的號碼)</TextBlue>
                </>
              )}
            </Field>
            <Field name="email" validate={rules.required('請輸入姓名')}>
              {({ input, meta }) => (
                <FormItem label="電郵地址：(用於接收確認預訂)" input={input} meta={meta} required>
                  <Input {...input} size="large"  placeholder="電郵地址"/>
                </FormItem>
                
              )}
            </Field>
           
            <Field name="address" validate={rules.required('請輸入姓名')}>
              {({ input, meta }) => (
                <FormItem label="地址" input={input} meta={meta} required>
                  <Input {...input} size="large" placeholder="地址" />
                </FormItem>
              )}
            </Field>
            <Field name="other_address">
              {({ input, meta }) => (
                <FormItem input={input} meta={meta} >
                  <Input {...input} size="large" placeholder="其他帐单地址" />
                </FormItem>
              )}
            </Field>
            
            <TextSmall>提供個人資料作項目及安排旅程之用;<br/>
              我同意接收旅遊或相關訊息
            </TextSmall>
            
          </LeftBox>

           <RightBox>
            {tour ? (
              <BorderBox>
                  <Title className="border">{tour.name}</Title>
                  <FlexBox><TextBlue>日期</TextBlue><TextBlue>{tour.planDate}</TextBlue></FlexBox>
                  <FlexBox><TextBlue>人數</TextBlue>
                  <TextBlue className="text-right">
                  {tour.package.map((item) => ( 
                    item.value !== 0 ?
                      <strong key={ item.key}><span>{item.key}{item.value}位</span><br/></strong>: null
                  ))
                  }
                  </TextBlue>
                  </FlexBox>
                  {tour.package && tour.package.map((item) => ( 
                    item.value !== 0 ?
                      <FlexBox key={item.key}><Text><SpanBlue>項目</SpanBlue>費用小計({item.key})</Text><Text>HKD{item.value* tour.itemPrice}</Text></FlexBox> :null 
                  ))
                  }
                  <FlexBox><Title><strong>總額</strong></Title><Title><strong>HKD {tour.price}</strong></Title></FlexBox>
                  <FlexBox><TextBlue>費用已包括旅遊印花費及保險費（如項目適用)</TextBlue></FlexBox>
              </BorderBox> ) : null
                }
                <BorderBox>
                  <Title>付款方式</Title>
                  <Radio name="stripe" value="1"  checked>
                    Stripe
                  </Radio>
                  <TextBlue>Stripe為安全付款平台， 無須開戶，涵蓋多種付款方式，例如：信用咭、支付寶、PayMe, FPS, Paypal</TextBlue>
              </BorderBox>
              <DefaulBox>
              <Checkbox onChange={handleCheckbox}>
                <TextBlue className="notSpace" fontWeight="500">我同意接收旅遊或相關訊息</TextBlue>
              </Checkbox>
              {errorCheck ? <TextSmall className="error">* Please accept terms</TextSmall>: null}
             </DefaulBox>
        
             <DefaulBox className="payment-button">
              <SubmitButton type="submit" disabled={!stripe} >{loading ? '處理中': '確認付款'}</SubmitButton>
            </DefaulBox>
          </RightBox>
          </Form>
        )}
      </FinalForm>
    </MainBox>
  );
};

export default CheckoutForm;
